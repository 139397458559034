/**
 * @file
 *
 * Project variables.
 *
 * @see https://chir.ag/projects/name-that-color/#6195ED
 */

$color-orange: rgba(202, 92, 1, .95);
$color-tenn: #C65900;
$color-boulder: #777;
//Footer Section.
$color-gray: #888;
$color-gray-light: #8B8B8B;
$color-mongoose: #B09E83;
$color-burnt-orange: #C54905;
$color-phone-icon-bg: #7F7F7F;
$color-bamboo: #DE6400;
$color-bamboo-light: #DE6302;
$color-white: #FFF;
$color-dusty-gray: #999;
$color-akaroa: #D9CCB3;
$color-kabul: #654E3D;
$color-pearl-bush: #ECE5D9;
$color-soft-amber: #CEC1AE;
$color-olive: #969100;
$color-dove-gray: #6E6E6E;
$color-mine-shaft: #1F1F1F;
$color-alabaster: #F7F7F7;
$color-hillary: #B09D84;
$color-alto: #DADADA;
$color-mantis: #56BE56;
$color-thunderbird: #B31717;
$color-kabul: #664F3F;

$font-default: "proxima-nova-n4", "proxima-nova", sans-serif;
$font-family-arial: Arial;
$font-awesome: 'Font Awesome 6 Regular';

$body-base-fsize: 16px;
$btn-fsize: 1.125em;

// DEFINE LINE HEIGHTS
$pdetail-lh: 1.5em;
//Detail page.
$pdetail-fsize: 1.125em;
$global-text-label-fsize: 2.250em;
$global-element-fsize: 1em;

$screen-375: 375px;
$screen-768: 768px;
$screen-820: 820px;
$screen-960: 960px;
$screen-1200: 1200px;
$screen-1300: 1300px;
$screen-767: 767px;
